import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import isServerSide from '@helpers/misc/isServerSide'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import isArray from 'lodash/isArray'

import useWebsiteId from './useWebsiteId'

// any of these roles
export default function UseHasUserRoleInWebsite(roles) {
  roles = isArray(roles) ? roles : [roles]

  const websiteId = useWebsiteId()
  const userPrefs = useUserPreferencesPartial(websiteId)

  const {me} = useQuery({
    query: gql`
      query getMeInWebsiteRoles($websiteId: ID) {
        me {
          _id
          roles
          roleInWebsite(websiteId: $websiteId) {
            _id
            role
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    partial: true,
    omit: !userPrefs?.data?.userPreferences.userId || isServerSide(),
  })

  if (isServerSide()) return false

  if (!me) return false
  if (me?.roles?.includes('admin')) return true

  if (!websiteId) return false

  if (!me.roleInWebsite) return false

  return roles.includes(me.roleInWebsite.role)
}
